import React, { RefObject, useEffect, useRef } from 'react'

import Jazzicon from 'jazzicon'
import styled from 'styled-components'

// hooks
import { useActiveWeb3React } from 'hooks'

const StyledIdenticonContainer = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  background-color: ${({ theme }) => theme.bg4};
`

export default function Identicon() {
  const ref = useRef<HTMLDivElement>()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = ''
      ref.current.appendChild(Jazzicon(16, parseInt(account.slice(2, 10), 16)))
    }
  }, [account])

  return <StyledIdenticonContainer ref={ref as RefObject<HTMLDivElement>} />
}

const LargeIdenticonContainer = styled(StyledIdenticonContainer)`
  height: 80px;
  width: 80px;
  border-radius: 40px;
`

export function LargeIdenticon() {
  const ref = useRef<HTMLDivElement>()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = ''
      ref.current.appendChild(Jazzicon(80, parseInt(account.slice(5, 20), 25)))
    }
  }, [account])

  return <LargeIdenticonContainer ref={ref as RefObject<HTMLDivElement>} />
}
