import React, { useRef, RefObject, useState, useContext } from 'react'

import styled, { ThemeContext } from 'styled-components'

// components
import Modal from 'components/Modal'
import Toggle from 'components/Toggle'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import SettingsButton from './SettingsButton'
import TransactionSettings from 'components/TransactionSettings'

// hooks
import { useOnClickOutside } from 'hooks/useOnClickOutside'

// state
import { useSilentModeManager, useUserTransactionTTL, useUserSlippageTolerance } from 'state/user/hooks'
import { ApplicationModal } from 'state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from 'state/application/hooks'

// styling
import { TYPE } from 'theme'

const StyledMenuIcon = styled(SettingsButton)`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg2};
  }
  color: ${({ theme }) => theme.text1};
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.bg1};
  border-radius: 20px;
`

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [ttl, setTtl] = useUserTransactionTTL()
  const [silentMode, toggleSilentMode] = useSilentModeManager()
  const [modalOpen, setModalOpen] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <StyledMenu ref={node as RefObject<HTMLDivElement>}>
      <StyledMenuButton onClick={() => setModalOpen(true)} id="open-settings-dialog-button">
        <StyledMenuIcon />
      </StyledMenuButton>
      <Modal isOpen={modalOpen} onDismiss={() => setModalOpen(false)}>
        <ModalContentWrapper>
          <AutoColumn gap="md" style={{ padding: '1rem' }}>
            <RowBetween style={{ marginBottom: '1rem' }}>
              <TYPE.panelHeader>Settings</TYPE.panelHeader>
            </RowBetween>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={ttl}
              setDeadline={setTtl}
            />
            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  Silent Mode
                </TYPE.black>
              </RowFixed>
              <Toggle isActive={silentMode} toggle={toggleSilentMode} />
            </RowBetween>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
    </StyledMenu>
  )
}
