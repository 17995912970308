import React from 'react'

import styled from 'styled-components'

// hooks
import useHttpLocations from 'hooks/useHttpLocations'

// components
import Logo from 'components/Logo'

const StyledListLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

interface ListLogoProps {
  logoURI: string
  size?: string
  style?: React.CSSProperties
  alt?: string
}

export default function ListLogo({ logoURI, style, size = '24px', alt }: ListLogoProps) {
  const srcs: string[] = useHttpLocations(logoURI)
  return <StyledListLogo alt={alt} size={size} srcs={srcs} style={style} />
}
