import React from 'react'

import styled from 'styled-components'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import HatPhoto from 'assets/images/hat.png'
import HatFacePhoto from 'assets/images/hat-face.png'
import HatSidePhoto from 'assets/images/hat-side.png'
import HatBackPhoto from 'assets/images/hat-back.png'

const HatImage = styled.img`
  width: 450px;
  height: 435px;
  object-fit: contain;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 355px;
    height: 320px;
  `};
`

const ImageWrapper = styled.div`
  width: 450px;
  height: 450px;
  outline: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 355px;
    height: auto;
  `};
`

const ImageSliderWrapper = styled(Slider)`
  width: 450px;
  height: 450px;
  margin: 1rem 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 355px;
    height: 500px;
  `};
`

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    vertical: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <ImageSliderWrapper {...settings}>
      <ImageWrapper>
        <HatImage src={HatFacePhoto}></HatImage>
      </ImageWrapper>
      <ImageWrapper>
        <HatImage src={HatPhoto}></HatImage>
      </ImageWrapper>
      <ImageWrapper>
        <HatImage src={HatSidePhoto}></HatImage>
      </ImageWrapper>
      <ImageWrapper>
        <HatImage src={HatBackPhoto}></HatImage>
      </ImageWrapper>
    </ImageSliderWrapper>
  )
}

export default ImageSlider
