import gql from 'graphql-tag'

const BUNDLE_ID = '1'

// get lending liquidations
export const lendingLiquidationsQuery = gql`
  query lendingLiquidationsQuery(
    $first: Int! = 20
    $user: String!
    $orderBy: String! = "blockTime"
    $orderDirection: String! = "desc"
  ) {
    liquidationEvents(first: $first, orderBy: $orderBy, orderDirection: $orderDirection, where: { borrower: $user }) {
      id
      borrower
      underlyingRepaySymbol
      underlyingRepayAmount
      underlyingCollateralSeizedSymbol
      underlyingCollateralSeizedAmount
      blockTime
    }
  }
`

// get total volume, for Earn page stats
export const dayDatasQuery = gql`
  query dayDatasQuery($dateAfter: Int! = 1622419200, $factory: String!) {
    dayDatas(orderBy: "date", orderDirection: "desc", where: { factory: $factory, date_gte: $dateAfter }) {
      date
      volumeUSD
      untrackedVolume
      txCount
    }
  }
`

// XJOE:JOE ratio
export const barsQuery = gql`
  query barsQuery($first: Int! = 5) {
    bars(first: $first) {
      id
      ratio
      joeStaked
      joeStakedUSD
    }
  }
`

export const tokenDaysQuery = gql`
  query tokensQuery($date: Int! = 1622419200, $first: Int! = 140) {
    tokens(first: $first, orderBy: volumeUSD, orderDirection: desc) {
      id
      name
      symbol
      decimals
      volumeUSD
      dayData(first: 8, where: { date_gte: $date }, orderBy: date, orderDirection: desc) {
        date
        volume
        volumeUSD
        volumeAVAX
        liquidity
        liquidityUSD
        liquidityAVAX
        priceUSD
      }
    }
  }
`

export const tokenPriceQuery = gql`
  query tokenQuery($id: String!) {
    token(id: $id) {
      id
      symbol
      name
      decimals
      totalSupply
      derivedAVAX
    }
    bundle(id: ${BUNDLE_ID}){
      avaxPrice
    }
  }
`

// for Pool detail
export const userPoolPositionQuery = gql`
  query liquidityPositionSubsetQuery(
    $first: Int! = 1
    $user: String!
    $pair: String!
    $orderBy: String! = "trackedReserveAVAX"
    $orderDirection: String! = "desc"
  ) {
    liquidityPositions(first: $first, where: { user: $user, pair: $pair }) {
      id
      liquidityTokenBalance
      user {
        id
      }
      pair {
        id
        name
        token0 {
          id
          symbol
          name
          decimals
          derivedAVAX
        }
        token1 {
          id
          symbol
          name
          decimals
          derivedAVAX
        }
        reserveUSD
        reserve0
        reserve1
        totalSupply
      }
    }
  }
`

// For pool detail
// We support search by token0/token1 select, by checking that token pair are in [token0, token1].
// This is ok because we assume no [token0, token0] pools, and that front-end will redirect.
export const pairDetailQuery = (tokens) => {
  const tokensString = `[${tokens
    .map((tok) => {
      return `"${tok}"`
    })
    .join(',')}]`
  const queryString = `
  query pairDetailQuery(
    $first: Int! = 1
    $orderBy: String! = "trackedReserveAVAX"
    $orderDirection: String! = "desc"
    $dateAfter: Int! = 1622419200
  ) {
    pairs (first: $first, orderBy: $orderBy, orderDirection: $orderDirection, 
      where: {
        token0_in: ${tokensString},
        token1_in: ${tokensString}
      }) {
      id
      name
      token0Price
      token1Price
      token0 {
        id
        symbol
        name
        decimals
        derivedAVAX
    }
      token1 {
        id
        symbol
        name
        decimals
        derivedAVAX
    }
      reserve0
      reserve1
      reserveUSD
      volumeUSD
      hourData(first: 24, where: {date_gt: $dateAfter}, orderBy: date, orderDirection: "desc") {
        volumeUSD
        untrackedVolumeUSD
        date
        volumeToken0
        volumeToken1
      }  
      timestamp
    }
  }
  `
  return gql(queryString)
}

// For Pools list
export const userLiquidityPositionsQuery = gql`
  query liquidityPositionSubsetQuery($first: Int! = 100, $user: Bytes!) {
    liquidityPositions(first: $first, where: { user: $user }) {
      id
      liquidityTokenBalance
      user {
        id
      }
      pair {
        id
        name
        token0 {
          id
          symbol
          decimals
        }
        token1 {
          id
          symbol
          decimals
        }
        reserveUSD
        reserve0
        reserve1
        totalSupply
      }
    }
  }
`

// For Farms list
export const pairsByIdQuery = gql`
  query filteredPairsQuery($pairIds: [String]!, $dateAfter: Int! = 1622419200) {
    pairs(where: { id_in: $pairIds }) {
      id
      name
      token0Price
      token1Price
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
      reserve0
      reserve1
      reserveUSD
      volumeUSD
      hourData(first: 24, where: { date_gt: $dateAfter }, orderBy: date, orderDirection: desc) {
        untrackedVolumeUSD
        volumeUSD
        date
        volumeToken0
        volumeToken1
      }
      timestamp
    }
  }
`

// For Pools List
export const pairsQuery = gql`
  query pairsQuery(
    $first: Int! = 100
    $skip: Int! = 0
    $orderBy: String! = "reserveUSD"
    $orderDirection: String! = "desc"
    $dateAfter: Int! = 1622419200
  ) {
    pairs(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      name
      token0Price
      token1Price
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
      reserve0
      reserve1
      reserveUSD
      volumeUSD
      hourData(first: 24, where: { date_gt: $dateAfter }, orderBy: date, orderDirection: desc) {
        untrackedVolumeUSD
        volumeUSD
        date
        volumeToken0
        volumeToken1
      }
      timestamp
    }
  }
`

export const dexCandlesQuery = gql`
  query dexCandlesQuery(
    $token0: String!
    $token1: String!
    $period: Int!
    $first: Int!
    $skip: Int!
    $startTime: Int!
  ) {
    candles(
      first: $first
      skip: $skip
      orderBy: time
      orderDirection: asc
      where: { token0: $token0, token1: $token1, period: $period, time_gt: $startTime }
    ) {
      time
      open
      low
      high
      close
    }
  }
`

export const launchEventFiveMinuteDatasQuery = gql`
  query launchEventFiveMinuteDatasQuery($launchEventId: String!, $first: Int!) {
    launchEventFiveMinuteDatas(
      first: $first
      orderBy: date
      orderDirection: desc
      where: { launchEvent: $launchEventId }
    ) {
      id
      date
      tokenReserve
      avaxReserve
      txCount
    }
  }
`

export const stableJoeDayDatasQuery = gql`
  query stableJoeDayDatas($first: Int!) {
    stableJoeDayDatas(first: $first, orderBy: date, orderDirection: desc) {
      id
      joeStaked
      joeStakedUSD
      joeUnstaked
      joeUnstakedUSD
    }
  }
`

export const remittancesQuery = gql`
  query remittancesQuery($first: Int!) {
    remits(first: $first, orderBy: timestamp, orderDirection: desc) {
      id
      token0Symbol
      amount0
      token1Symbol
      amount1
      tokenRemitted
      usdRemitted
      timestamp
    }
  }
`

export const moneyMakerDayDatasQuery = gql`
  query moneyMakerDayDatasQuery($dateAfter: Int!) {
    dayDatas(orderBy: date, orderDirection: desc, where: { date_gte: $dateAfter }) {
      id
      date
      usdRemitted
    }
  }
`
