// pangolindex
import { InjectedConnector } from '@pangolindex/web3-react-injected-connector'

// @web3-react
import { WalletConnectConnector } from './wallet-connect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

// gnosis safe
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react'

// constants
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'

// local connectors
import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '43114')

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

const NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/4bf032f2d38a4ed6bb975b80d6340847`,
  [SupportedChainId.FUJI]: `https://api.avax-test.network/ext/bc/C/rpc`,
  [SupportedChainId.AVAX]: NETWORK_URL,
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  defaultChainId: NETWORK_CHAIN_ID,
})

export const injected = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})

export const gnosisSafe = new SafeAppConnector()

export const walletconnect = new WalletConnectConnector({
  rpc: {
    [SupportedChainId.AVAX]: NETWORK_URLS[SupportedChainId.AVAX],
    [SupportedChainId.RINKEBY]: NETWORK_URLS[SupportedChainId.RINKEBY],
  },
  bridge: 'https://aave.bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000,
  preferredNetworkId: SupportedChainId.AVAX,
})

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'Trader Joe',
  appLogoUrl:
    'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd/logo.png',
})
