import React, { useContext, useMemo, ReactNode } from 'react'

import { Text } from 'rebass'
import { stringify } from 'qs'
import { useLocation } from 'react-router'
import { ThemeContext } from 'styled-components'

// hooks
import useParsedQueryString from 'hooks/useParsedQueryString'
import useToggledVersion, { DEFAULT_VERSION, Version } from 'hooks/useToggledVersion'

// styling
import { StyledInternalLink } from 'theme'

// components
import { YellowCard } from 'components/Card'
import { AutoColumn } from 'components/Column'

interface VersionLinkContainerProps {
  children: ReactNode
}

interface BetterTradeLinkProps {
  version: Version
}

function VersionLinkContainer({ children }: VersionLinkContainerProps) {
  const theme = useContext(ThemeContext)

  return (
    <YellowCard style={{ marginTop: '12px', padding: '0.5rem 0.5rem' }}>
      <AutoColumn gap="sm" justify="center" style={{ alignItems: 'center', textAlign: 'center' }}>
        <Text lineHeight="145.23%;" fontSize={14} fontWeight={400} color={theme.text1}>
          {children}
        </Text>
      </AutoColumn>
    </YellowCard>
  )
}

export default function BetterTradeLink({ version }: BetterTradeLinkProps) {
  const location = useLocation()
  const search = useParsedQueryString()
  const linkDestination = useMemo(() => {
    return {
      ...location,
      search: `?${stringify({
        ...search,
        use: version !== DEFAULT_VERSION ? version : undefined,
      })}`,
    }
  }, [location, search, version])

  return (
    <VersionLinkContainer>
      There is a better price for this trade on{' '}
      <StyledInternalLink to={linkDestination}>
        <b>Uniswap {version.toUpperCase()} ↗</b>
      </StyledInternalLink>
    </VersionLinkContainer>
  )
}

export function DefaultVersionLink() {
  const location = useLocation()
  const search = useParsedQueryString()
  const version = useToggledVersion()

  const linkDestination = useMemo(() => {
    return {
      ...location,
      search: `?${stringify({
        ...search,
        use: DEFAULT_VERSION,
      })}`,
    }
  }, [location, search])

  return (
    <VersionLinkContainer>
      Showing {version.toUpperCase()} price.{' '}
      <StyledInternalLink to={linkDestination}>
        <b>Switch to Uniswap {DEFAULT_VERSION.toUpperCase()} ↗</b>
      </StyledInternalLink>
    </VersionLinkContainer>
  )
}
