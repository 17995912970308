import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import { ButtonPrimary } from 'components/Button'

import Modal from 'components/Modal'
import { useWeb3React } from '@web3-react/core'
import emailjs from '@emailjs/browser'
import { ChainId } from '@traderjoe-xyz/sdk'

// variables for the netlify names of each form field
const name = 'name'
const line1 = 'line1'
const line2 = 'line2'
const city = 'city'
const state = 'state'
const zip = 'zip'
const country = 'country'
const email = 'email'
const phoneNumber = 'phoneNumber'
const timestamp = 'timestamp'

// map from variables to display text for each field
const nameMap = {
  [name]: 'Name',
  [line1]: 'Street Address',
  [line2]: 'Unit',
  [city]: 'City',
  [state]: 'State',
  [zip]: 'ZIP',
  [country]: 'Country',
  [email]: 'Email',
  [phoneNumber]: 'Phone Number',
  [timestamp]: 'Time',
}

// default for each form field
const defaultState = {
  [name]: '',
  [line1]: '',
  [line2]: '',
  [city]: '',
  [state]: '',
  [zip]: '',
  [country]: '',
  [email]: '',
  [phoneNumber]: '',
}

export default function RedeemForm({
  isOpen,
  setOpen,
  setHasConfirmedAddress,
  txHash
}) {
  const { library, account, chainId } = useWeb3React()

  const [formState, setFormState] = useState(defaultState)
  const [isSending, setSending] = useState(false)

  function handleChange(event) {
    const { name, value } = event.target
    setFormState((state) => ({ ...state, [name]: value }))
  }

  const canSign =
    formState[name] &&
    formState[line1] &&
    formState[city] &&
    formState[state] &&
    formState[zip] &&
    formState[country] &&
    formState[email] &&
    formState[phoneNumber]

  return (
    <Modal isOpen={isOpen} onDismiss={() => setOpen(false)}>
      <FormFrame autocomplete="off">
        <input
          required
          type="text"
          name={name}
          value={formState[name]}
          onChange={handleChange}
          placeholder={nameMap[name]}
          autoComplete="name"
        />
        <Compressed>
          <input
            required
            type="text"
            name={line1}
            value={formState[line1]}
            onChange={handleChange}
            placeholder={nameMap[line1]}
            autoComplete="off"
          />

          <input
            type="text"
            name={line2}
            value={formState[line2]}
            onChange={handleChange}
            placeholder={nameMap[line2]}
            autoComplete="off"
          />
        </Compressed>
        <input
          required
          type="text"
          name={city}
          value={formState[city]}
          onChange={handleChange}
          placeholder={nameMap[city]}
          autoComplete="address-level2"
        />

        <Compressed>
          <input
            required
            type="text"
            name={state}
            value={formState[state]}
            onChange={handleChange}
            placeholder={nameMap[state]}
            autoComplete="address-level1"
          />
          <input
            required
            type="text"
            name={zip}
            value={formState[zip]}
            onChange={handleChange}
            placeholder={nameMap[zip]}
            autoComplete="postal-code"
          />
        </Compressed>

        <input
          required
          type="text"
          name={country}
          value={formState[country]}
          onChange={handleChange}
          placeholder={nameMap[country]}
          autoComplete="country-name"
        />

        <input
          required
          type="email"
          name={email}
          value={formState[email]}
          onChange={handleChange}
          placeholder={nameMap[email]}
          autoComplete="email"
        />

        <input
          required
          type="tel"
          name={phoneNumber}
          value={formState[phoneNumber]}
          onChange={handleChange}
          placeholder={nameMap[phoneNumber]}
          autoComplete="tel"
        />

        <ButtonPrimary
          type="submit"
          marginTop={10}
          disabled={!canSign || isSending}
          onClick={(event) => {
            event.preventDefault()
            setSending(true)
              emailjs
                .send(
                  process.env.REACT_APP_EMAIL_SERVICE_ID,
                  process.env.REACT_APP_EMAIL_TEMPLATE_ID,
                  { ...formState, chainId: chainId === ChainId.AVALANCHE ? `${chainId} - Avalanche Mainnet C-Chain` : `${chainId} - THIS IS NOT MAINNET!!`, account: account ?? 'none', txHash: txHash },
                  process.env.REACT_APP_EMAIL_USER_ID
                )
                .then(
                  (result) => {
                    setSending(false)
                    setOpen(false)
                    setHasConfirmedAddress(true)
                  },
                  (error) => {
                    console.error(error)
                    setSending(false)
                  }
                )
          }}
        >
          {canSign ? (isSending ? 'Sending...' : 'Next') : 'Complete the form to continue'}
        </ButtonPrimary>
        <br />
      </FormFrame>
    </Modal>
  )
}

const FormFrame = styled.form`
  width: 100%;
  color: #fff;
  font-weight: 600;
  margin: 16px;
  /* margin-bottom: 0px; */
  font-size: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #000;
    background-color: #f1f2f6;
    padding: 8px;
    margin: 4px 0 4px 0;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
  }
  input:required {
    box-shadow: inset 0 0 0 1px rgba(126, 132, 255, 1);
  }
  input:valid {
    border: nne;
    box-shadow: none;
  }
`

const Compressed = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
`
