import styled from 'styled-components'
import { Box } from 'rebass/styled-components'

const Card = styled(Box)<{
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg4};
  background-color: ${({ theme }) => theme.bg2};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg3};
`

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow2};
  font-weight: 500;
`

export const RedCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.avaxRed};
  font-weight: 500;
`

export default Card
