import React from 'react'

import styled from 'styled-components'
import { darken, lighten } from 'polished'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

// components
import { RowBetween } from 'components/Row'

const Base = styled(RebassButton)<{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '18px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  border-radius: 12px;
  border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`

export const ButtonPrimary = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.primary2};
  border: 1px solid ${({ theme }) => theme.primary2};
  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    border-color: ${({ theme }) => darken(0.05, theme.primary2)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    border-color: ${({ theme }) => darken(0.05, theme.primary2)};
  }
  &:active {
    box-shadow: 0px 1px 0px 1px ${({ theme }) => theme.primary2};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.primary1 : theme.bg3)};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.text3 : theme.text3)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')};
  }
  box-shadow: 0px 3px 0px 1px ${({ theme }) => theme.primary2};
`

export const ButtonLight = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.primary2};
  border: 1px solid ${({ theme }) => theme.primary2};
  border-radius: 12px;
  padding: 12px;
  font-size: 14px;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    border-color: ${({ theme }) => darken(0.05, theme.primary2)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.primary1)};
    border-color: ${({ theme }) => darken(0.05, theme.primary2)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.primary1)};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.primary5};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonSecondary = styled(Base)`
  border: 1px solid ${({ theme }) => theme.primary4};
  color: ${({ theme }) => theme.primary2};
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.primary4};
    border: 1px solid ${({ theme }) => theme.primary3};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: transparent;
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 1px solid ${({ theme }) => theme.green1};

  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.red2};
  border: 1px solid ${({ theme }) => theme.red2};
  &:focus {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
    border-color: ${({ theme }) => darken(0.05, theme.red2)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.red1)};
    border-color: ${({ theme }) => darken(0.05, theme.red2)};
  }
  &:active {
    box-shadow: 0px 1px 0px 1px ${({ theme }) => theme.red2};
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? theme.red1 : theme.bg3)};
    color: ${({ theme, altDisabledStyle }) => (altDisabledStyle ? 'white' : theme.text3)};
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')};
  }
  box-shadow: 0px 3px 0px 1px ${({ theme }) => theme.red2};
`

export const SmallFlatButton = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary1};
  border: 1px solid ${({ theme }) => theme.primary1};
  border-radius: 10px;
  font-size: 0.875rem;
  padding: 0 0.3rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.text1};
  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }
  &:disabled {
    color: ${({ theme }) => theme.text5};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`

interface ButtonConfirmedProps extends ButtonProps {
  confirmed?: boolean
  altDisabledStyle?: boolean
}

interface ButtonErrorProps extends ButtonProps {
  error?: boolean
}

interface ButtonDropdownProps extends ButtonProps {
  disabled?: boolean
}

export function ButtonConfirmed({ confirmed, altDisabledStyle, ...rest }: ButtonConfirmedProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: ButtonErrorProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: ButtonDropdownProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}
