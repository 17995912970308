import { useCallback, useEffect, useState } from 'react'

// hooks
import { useActiveWeb3React } from 'hooks'

// apollo
import { exchangeGraph } from './apollo/client'
import { tokenDaysQuery } from './apollo/queries'

// ethers
import { ethers } from 'ethers'

// utils
import { BN_18 } from 'utils'

// entities
import { Fraction } from 'entities/index'

// dayjs
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const useAvaxPrice = () => {
  const [avax, setAvax] = useState<number | undefined>()

  const fetchAvaxPrice = useCallback(async () => {
    try {
      const url = new URL('https://api.traderjoexyz.com/priceusd/avax')
      const response = await fetch(url.toString())
      const data = await response.json()
      if (data) {
        const _price = ethers.utils.parseUnits(String(data), 0)
        const price = Fraction.from(_price, BN_18).toString(18)
        setAvax(parseFloat(price))
      }
    } catch (e) {
      console.error(e)
      setAvax(undefined)
    }
  }, [])

  useEffect(() => {
    fetchAvaxPrice()
  }, [fetchAvaxPrice])
  return avax
}

export const usePriceViaTraderJoeAPI = (tokenAddress: string | undefined | null) => {
  const [price, setPrice] = useState<number | undefined>()

  const fetchPrice = useCallback(async () => {
    try {
      const url = new URL(`https://api.traderjoexyz.com/priceusd/${tokenAddress}`)
      const response = await fetch(url.toString())
      const data = await response.json()

      if (data) {
        const price = Number(data) / 1e18
        setPrice(price)
      }
    } catch (e) {
      console.error(e)
      setPrice(undefined)
    }
  }, [])

  useEffect(() => {
    fetchPrice()
  }, [fetchPrice])
  return price
}

const useTokens = () => {
  const { chainId } = useActiveWeb3React()
  const exchange = exchangeGraph(chainId, true)
  const [tokens, setTokens] = useState<any | undefined>()

  const utcToday = dayjs().utc().startOf('day').unix()
  const utcOneDayBack = dayjs().utc().startOf('day').subtract(1, 'day').unix()
  const utcTwoDayBack = dayjs().utc().startOf('day').subtract(2, 'day').unix()
  const utcSevenDayBack = dayjs().utc().startOf('day').subtract(7, 'day').unix()

  const fetchTokens = useCallback(async () => {
    try {
      const results = await Promise.all([
        exchange.query({
          query: tokenDaysQuery,
          variables: { date: utcSevenDayBack, first: 1000 }, // results [3]
        }),
      ])

      // index day data by token address and dte
      const indexedTokenDay: { [address: string]: any } = {}
      const rawData = results[0]?.data?.tokens

      //Subgraph updates slowly so there will be times when we cannot retrieve the current day's data and will have to rely on old data
      const todayDataNotAvailable = rawData.every((token: any) => {
        const { dayData } = token
        const todayData = dayData.find((d: { date: number }) => d.date === utcToday) || {}
        return Object.keys(todayData).length === 0
      })

      rawData.map((data: any) => {
        const { id, name, symbol, decimals, dayData } = data
        const todayData = dayData.find((d: { date: number }) => d.date === utcToday) || {}
        const oneDayData = dayData.find((d: { date: number }) => d.date === utcOneDayBack) || {}
        const twoDayData = dayData.find((d: { date: number }) => d.date === utcTwoDayBack) || {}
        const sevenDayData = dayData.find((d: { date: number }) => d.date === utcSevenDayBack) || {}
        indexedTokenDay[id] = { id, name, symbol, decimals }
        indexedTokenDay[id]['todayData'] = todayData
        indexedTokenDay[id]['oneDayData'] = oneDayData
        indexedTokenDay[id]['twoDayData'] = twoDayData
        indexedTokenDay[id]['sevenDayData'] = sevenDayData
      })

      const tokens: any[] = []
      for (const [, data] of Object.entries(indexedTokenDay)) {
        const todayData = todayDataNotAvailable ? data['oneDayData'] : data['todayData']
        const oneDayBackData = todayDataNotAvailable ? data['twoDayData'] : data['oneDayData']
        const sevenDayBackData = data['sevenDayData']

        // get prices
        const price = parseFloat(todayData?.priceUSD)
        const priceYesterday = parseFloat(oneDayBackData?.priceUSD)
        const priceLastWeek = parseFloat(sevenDayBackData?.priceUSD)

        // price change in decimal
        const priceChange = (price - priceYesterday) / priceYesterday
        const sevenDayPriceChange = priceLastWeek ? (price - priceLastWeek) / priceLastWeek : 'n/a'

        // liquidity
        const volumeYesterday = parseFloat(oneDayBackData?.volumeUSD)

        const entry = {
          ...data,
          price,
          priceYesterday,
          priceChange,
          priceLastWeek,
          sevenDayPriceChange,
          volumeYesterday,
        }
        tokens.push(entry)
      }

      setTokens(tokens)
    } catch (e) {
      console.error('[userTokens] error: ' + e)
      setTokens(undefined)
    }
  }, [utcOneDayBack, utcSevenDayBack, utcToday])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])
  return tokens
}

export default useTokens
