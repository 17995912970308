import React from 'react'

import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean }>`
  padding: 0.25rem 0.5rem;
  border-radius: 14px;
  background: ${({ theme, isActive }) => (isActive ? theme.primary1 : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.black : theme.text3)};
  font-size: 1rem;
  font-weight: 400;

  padding: 0.35rem 0.6rem;
  border-radius: 12px;
  background: ${({ theme, isActive }) => (isActive ? theme.portfolioButtonBG : 'none')};
  color: ${({ theme, isActive }) => (isActive ? theme.text2 : theme.black)};
  font-size: 1rem;
`

const StyledToggle = styled.button<{
  isActive?: boolean
  activeElement?: boolean
}>`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.bg7};
  background-color: ${({ theme }) => theme.bg7};
  display: flex;
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 0;
`

interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive}>On</ToggleElement>
      <ToggleElement isActive={!isActive}>Off</ToggleElement>
    </StyledToggle>
  )
}
