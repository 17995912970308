import { Token, ChainId, WAVAX, JSBI, Percent } from '@traderjoe-xyz/sdk'
import { ZERO_ADDRESS } from '.'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// Pangolin factory address used to fetch Pangolin LPs for JoeRoll
export const PANGOLIN_FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.RINKEBY]: '0xE2eCc226Fd2D5CEad96F3f9f00eFaE9fAfe75eB8',
  [ChainId.FUJI]: ZERO_ADDRESS,
  [ChainId.AVALANCHE]: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
}

const USDT: { [chainId in ChainId]: Token } = {
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, ZERO_ADDRESS, 18, 'USDT', 'Tether USD'),
  [ChainId.FUJI]: new Token(ChainId.FUJI, ZERO_ADDRESS, 6, 'USDT', 'Tether USD'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    6,
    'USDT.e',
    'Tether USD'
  ),
}

const USDC: { [chainId in ChainId]: Token } = {
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, ZERO_ADDRESS, 18, 'USDT', 'Tether USD'),
  [ChainId.FUJI]: new Token(ChainId.FUJI, ZERO_ADDRESS, 6, 'USDT', 'Tether USD'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
    6,
    'USDC.e',
    'USD Coin'
  ),
}

const DAI: { [chainId in ChainId]: Token } = {
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin'),
  [ChainId.FUJI]: new Token(ChainId.FUJI, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
    18,
    'DAI.e',
    'Dai Stablecoin'
  ),
}

const MIM: { [chainId in ChainId]: Token } = {
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, ZERO_ADDRESS, 18, 'MIM', 'Magic Internet Money'),
  [ChainId.FUJI]: new Token(ChainId.FUJI, ZERO_ADDRESS, 18, 'MIM', 'Magic Internet Money'),
  [ChainId.AVALANCHE]: new Token(
    ChainId.AVALANCHE,
    '0x130966628846BFd36ff31a822705796e8cb8C18D',
    18,
    'MIM',
    'Magic Internet Money'
  ),
}

const WAVAX_ONLY: ChainTokenList = {
  [ChainId.RINKEBY]: [WAVAX[ChainId.RINKEBY]], // This is actually WETH
  [ChainId.FUJI]: [WAVAX[ChainId.FUJI]],
  [ChainId.AVALANCHE]: [WAVAX[ChainId.AVALANCHE]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WAVAX_ONLY,
  [ChainId.AVALANCHE]: [
    ...WAVAX_ONLY[ChainId.AVALANCHE],
    USDT[ChainId.AVALANCHE],
    DAI[ChainId.AVALANCHE],
    USDC[ChainId.AVALANCHE],
    MIM[ChainId.AVALANCHE],
  ],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] }
} = {
  [ChainId.AVALANCHE]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WAVAX_ONLY,
  [ChainId.AVALANCHE]: [...WAVAX_ONLY[ChainId.AVALANCHE]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WAVAX_ONLY,
  [ChainId.AVALANCHE]: [...WAVAX_ONLY[ChainId.AVALANCHE]],
}

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][]
} = {
  [ChainId.AVALANCHE]: [],
}

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 60 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 60

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

/*
 * Whitelist of tokens to skip warning when swapping tokens
 */
export const SWAP_WHITE_LIST = [
  '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', // WAVAX
  '0xc7198437980c041c805A1EDcbA50c1Ce5db95118', // USDT.e
  '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664', // USDC.e
  '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70', // DAI.e
  '0x130966628846BFd36ff31a822705796e8cb8C18D', // MIM
  '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd', // JOE
]

// Major stable coins
export const USDC_ADDRESS = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'.toLowerCase()
export const STABLE_COINS = [
  '0x130966628846BFd36ff31a822705796e8cb8C18D'.toLowerCase(), // MIM
  '0xd586e7f844cea2f87f50152665bcbc2c279d8d70'.toLowerCase(), // DAI.e
  '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664'.toLowerCase(), // USDC.e
  USDC_ADDRESS, // USDC
  '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'.toLowerCase(), // USDT.e
  '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7'.toLowerCase(), // USDT
  '0x260bbf5698121eb85e7a74f2e45e16ce762ebe11'.toLowerCase(), // UST
]
