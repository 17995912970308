import React from 'react'

interface SettingsButtonProps {
  size?: string
}

export default function SettingsButton({ size = '14', ...rest }: SettingsButtonProps) {
  return (
    <div {...rest}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="9.625" width="8.75" height="1.75" rx="0.875" fill="currentColor" />
        <rect x="5.25" y="2.625" width="8.75" height="1.75" rx="0.875" fill="currentColor" />
        <circle cx="12.25" cy="10.5" r="1.75" fill="currentColor" />
        <circle cx="1.75" cy="3.5" r="1.75" fill="currentColor" />
      </svg>
    </div>
  )
}
