import React, { RefObject, useState, useRef } from 'react'

import styled from 'styled-components'

// components
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import RoundContainer from 'components/RoundContainer'

// styling
import { TYPE } from 'theme'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh',
}

enum DeadlineError {
  InvalidInput = 'InvalidInput',
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: none;
  outline: none;
  background: none;
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  background-color: ${({ active, theme }) => (active ? theme.primary2 : theme.primary1)};
  color: ${({ active, theme }) => (active ? theme.white : theme.text1)};
  border-radius: 10px;
  font-size: 0.875rem;
  padding: 0 0.3rem;
`

const Input = styled.input`
  background: none;
  font-size: 16px;
  width: auto;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: left;
`

const OptionCustom = styled(FancyButton)<{
  active?: boolean
  warning?: boolean
}>`
  height: 2rem;
  position: relative;
  padding: 0 0.75rem;
  padding-right: 2rem;
  flex: 1;

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2rem;
  }
`

const SlippageEmojiContainer = styled.span`
  color: ${({ theme }) => theme.primary4}
    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage, deadline, setDeadline }: SlippageTabsProps) {
  const inputRef = useRef<HTMLInputElement>()
  const [slippageInput, setSlippageInput] = useState('')
  const [deadlineInput, setDeadlineInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)
  const deadlineInputIsValid = deadlineInput === '' || (deadline / 60).toString() === deadlineInput

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  let deadlineError: DeadlineError | undefined
  if (deadlineInput !== '' && !deadlineInputIsValid) {
    deadlineError = DeadlineError.InvalidInput
  } else {
    deadlineError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)
    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  function parseCustomDeadline(value: string) {
    setDeadlineInput(value)
    try {
      const valueAsInt: number = Number.parseInt(value) * 60
      if (!Number.isNaN(valueAsInt) && valueAsInt > 0) {
        setDeadline(valueAsInt)
      }
    } catch {}
  }

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <TYPE.subHeader>Slippage tolerance</TYPE.subHeader>
          <QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%" />
        </RowFixed>
        <RoundContainer>
          <RowBetween>
            <OptionCustom active={![10, 50, 100].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
              <RowBetween>
                {!!slippageInput &&
                (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
                  <SlippageEmojiContainer>
                    <span role="img" aria-label="warning">
                      ⚠️
                    </span>
                  </SlippageEmojiContainer>
                ) : null}
                {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
                <Input
                  ref={inputRef as RefObject<HTMLInputElement>}
                  placeholder={(rawSlippage / 100).toFixed(2)}
                  value={slippageInput}
                  onBlur={() => {
                    parseCustomSlippage((rawSlippage / 100).toFixed(2))
                  }}
                  onChange={(e) => parseCustomSlippage(e.target.value)}
                  color={!slippageInputIsValid ? 'red' : ''}
                />
                %
              </RowBetween>
            </OptionCustom>
            <Option
              onClick={() => {
                setSlippageInput('')
                setRawSlippage(10)
              }}
              active={rawSlippage === 10}
            >
              0.1%
            </Option>
            <Option
              onClick={() => {
                setSlippageInput('')
                setRawSlippage(50)
              }}
              active={rawSlippage === 50}
            >
              0.5%
            </Option>
            <Option
              onClick={() => {
                setSlippageInput('')
                setRawSlippage(100)
              }}
              active={rawSlippage === 100}
            >
              1%
            </Option>
          </RowBetween>
        </RoundContainer>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError === SlippageError.InvalidInput ? 'red' : '#F3841E',
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </RowBetween>
        )}
      </AutoColumn>
      <AutoColumn gap="sm" style={{ marginTop: '1rem' }}>
        <RowFixed>
          <TYPE.subHeader>Transaction deadline</TYPE.subHeader>
          <QuestionHelper text="Your transaction will revert if it is pending for more than this long." />
        </RowFixed>
        <RoundContainer>
          <RowBetween>
            <OptionCustom style={{ width: '80px' }} tabIndex={-1}>
              <Input
                color={!!deadlineError ? 'red' : undefined}
                onBlur={() => {
                  parseCustomDeadline((deadline / 60).toString())
                }}
                placeholder={(deadline / 60).toString()}
                value={deadlineInput}
                onChange={(e) => parseCustomDeadline(e.target.value)}
              />
            </OptionCustom>
            <TYPE.body style={{ paddingLeft: '8px', marginRight: '1rem' }} fontSize={14}>
              minutes
            </TYPE.body>
          </RowBetween>
        </RoundContainer>
      </AutoColumn>
    </AutoColumn>
  )
}
