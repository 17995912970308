import React from 'react'
import styled from 'styled-components'

import { isMobile } from 'react-device-detect'
import { AlertTriangle, X, Zap } from 'react-feather'

// state
import { useURLWarningToggle, useURLWarningVisible } from 'state/user/hooks'

// constants
import { HOSTNAME } from 'constants/index'

const PhishAlert = styled.div<{ isActive: boolean }>`
  width: 100%;
  padding: 6px 6px;
  background-color: ${({ theme }) => theme.blue1};
  color: white;
  font-size: 11px;
  justify-content: space-between;
  align-items: center;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
`

const Headline = styled(PhishAlert)`
  background-color: ${({ theme }) => theme.primary2};
`

const StyledClose = styled(X)`
  :hover {
    cursor: pointer;
  }
`

export default function URLWarning() {
  const toggleURLWarning = useURLWarningToggle()
  const showURLWarning = useURLWarningVisible()
  const showHeadline = false

  return showHeadline ? (
    <Headline isActive={true}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <Zap style={{ marginRight: 6 }} size={12} />
        Our website has moved to
        <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>{HOSTNAME}</code> - bookmark it to be
        safe.
      </div>
    </Headline>
  ) : isMobile ? (
    <PhishAlert isActive={showURLWarning}>
      <div style={{ display: 'flex' }}>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure the URL is
        <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>{HOSTNAME}</code>
      </div>
      <StyledClose size={12} onClick={toggleURLWarning} />
    </PhishAlert>
  ) : window.location.hostname === HOSTNAME ? (
    <PhishAlert isActive={showURLWarning}>
      <div style={{ display: 'flex' }}>
        <AlertTriangle style={{ marginRight: 6 }} size={12} /> Always make sure the URL is
        <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>{HOSTNAME}</code> - bookmark it to be
        safe.
      </div>
      <StyledClose size={12} onClick={toggleURLWarning} />
    </PhishAlert>
  ) : null
}
