import React, { useEffect, useState } from 'react'
import Confetti from 'react-dom-confetti'

// style
import styled from 'styled-components'
import { TYPE } from 'theme'

// pages
import Swap from '../Swap'

// hooks
import { useActiveWeb3React } from 'hooks/index'
import useHat from 'hooks/useHat'
import useTokenBalance from 'hooks/useTokenBalance'
import { useHasPendingHatRedeem, useHasRedeemHat } from 'state/transactions/hooks'
import { usePriceViaTraderJoeAPI } from 'hooks/useTokens'
import usePrevious from 'hooks/usePrevious'

// components
import { ButtonPrimary } from 'components/Button'
import DeliveryInfoForm from 'components/DeliveryInfoForm'
import Modal from 'components/Modal'
import ImageSlider from 'components/ImageSlider'
import { AlertTriangle } from 'react-feather'

// constants
import { HAT_ADDRESS } from 'constants/index'

// utils
import { formatFromBalance } from 'utils'

// traderjoe sdk
import { ChainId } from '@traderjoe-xyz/sdk'

const Wrapper = styled.div`
  display: flex
  flex-direction: column;
  align-items: center;
  height: 90vh;
  gap: 1rem;
`

const HatSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;
  border: 1px solid #ebebf5;
  overflow: scroll;
  padding: 1.5rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
  `};
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 90%;
  `};
`

const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

const ConfirmedModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
`

const CheckoutPrompt = styled.p`
  font-weight: 500;
  font-size: 14px;
  margin: 24px 16px 0 16px !important;
  text-align: center;
  color: '#000';
  font-style: italic;
  width: 100%;
`

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  background-color: #fff3e5;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #ec9b50;
`

const config = {
  angle: 90,
  spread: 76,
  startVelocity: 51,
  elementCount: 154,
  dragFriction: 0.1,
  duration: 7000,
  stagger: 0,
  width: '10px',
  height: '10px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}

const Home = () => {
  const { account, chainId } = useActiveWeb3React()
  const hatAddress = chainId ? HAT_ADDRESS[chainId] : HAT_ADDRESS[ChainId.AVALANCHE]
  const { txHash, redeem } = useHat()

  const hasPendingRedeem = useHasPendingHatRedeem()
  const previousHasPendingRedeem = usePrevious(hasPendingRedeem)
  const hasRedeem = useHasRedeemHat()

  const [isSwapOpen, setSwapOpen] = useState(false)
  const [isDeliveryInfoOpen, setDeliveryInfoOpen] = useState(false)

  const [hasConfirmedAddress, setHasConfirmatedAddress] = useState(false)

  const hatBalanceBigInt = useTokenBalance(hatAddress)
  const hatBalance = formatFromBalance(hatBalanceBigInt?.value, hatBalanceBigInt?.decimals)

  const hatPrice = usePriceViaTraderJoeAPI(HAT_ADDRESS[ChainId.AVALANCHE])
  const isRedeemEnabled = Number(hatBalance) >= 1

  useEffect(() => {
    setDeliveryInfoOpen((previousHasPendingRedeem ?? false) && !hasPendingRedeem)
  }, [hasRedeem, hasPendingRedeem])

  const onRedeemClick = async () => {
    try {
      await redeem()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Wrapper>
        <Swap isOpen={isSwapOpen} setOpen={setSwapOpen} />
        <DeliveryInfoForm
          isOpen={isDeliveryInfoOpen}
          setOpen={setDeliveryInfoOpen}
          setHasConfirmedAddress={setHasConfirmatedAddress}
          txHash={txHash}
        />
        <Modal isOpen={hasConfirmedAddress} onDismiss={() => setHasConfirmatedAddress(false)}>
          <ConfirmedModalWrapper>
            <TYPE.mediumHeader>You got a hat!</TYPE.mediumHeader>
            <CheckoutPrompt>
              Estimated shipping time 2-3 weeks. <br /> Shipping time will vary by region.
            </CheckoutPrompt>
          </ConfirmedModalWrapper>
        </Modal>
        <HatSection>
          <TYPE.largeHeader marginBottom={2}>Joe Hat</TYPE.largeHeader>
          {hatPrice ? (
            <TYPE.mediumGray marginBottom={2}>{`1 HAT = ${hatPrice.toFixed(2)} USD`}</TYPE.mediumGray>
          ) : undefined}
          {account ? <TYPE.mediumGray>{`Your balance: ${hatBalance} HAT`}</TYPE.mediumGray> : undefined}
          <ImageSlider />
        </HatSection>
        <ButtonsWrapper>
          <ButtonPrimary disabled={!isRedeemEnabled || hasPendingRedeem} onClick={onRedeemClick}>
            {hasPendingRedeem ? <Dots>Redeeming </Dots> : 'Redeem'}
          </ButtonPrimary>
          <ButtonPrimary onClick={() => setSwapOpen(true)}>Swap HAT</ButtonPrimary>
        </ButtonsWrapper>
        {Number(hatBalance) >= 1 ? (
          <DisclaimerWrapper>
            <AlertTriangle color="#EC9B50" size="18" />
            Please note that redeeming a hat burns 1 HAT token.
          </DisclaimerWrapper>
        ) : undefined}
      </Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Confetti active={hasConfirmedAddress} config={config} />
      </div>
    </>
  )
}

export default Home
