import React, { useEffect, useState } from 'react'

import ReactGA from 'react-ga'
import { darken } from 'polished'
import { isMacOs } from 'react-device-detect'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory, Link, NavLink } from 'react-router-dom'

// @traderjoe-xyz sdk
import { ChainId } from '@traderjoe-xyz/sdk'

// assets
import Logo from 'assets/images/logo.png'
import LogoDark from 'assets/images/logo.png'

// hooks
import { useActiveWeb3React } from 'hooks'

// state
import { useDarkModeManager } from 'state/user/hooks'

// styling
import { ExternalLink } from 'theme'

// components
import Row from 'components/Row'
import { RedCard } from 'components/Card'
import Web3Status from 'components/Web3Status'

// constants
import { SNAPSHOT_PAGE } from 'constants/index'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  background-color: theme.bg8;
  flex-direction: row;
  width: 100%;
  top: 0;
  border-bottom: '1px solid'
  border-color: ${({ theme }) => theme.borderColor};
  padding: 1rem;
  z-index: 2;
  ${({ theme, className }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
    background-color: ${className === 'lending' ? theme.cardBG : 'transparent'}
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.5rem 1rem;
  `}

  & * a {
    color: ${({ theme, className }) =>
      className === 'lending' || className === 'search' ? `${theme.white} !important` : 'text1 !important'}
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `};
`

const HeaderLinks = styled(Row)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-column-gap: 1rem;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 0.5rem;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  // width: 100%;
  width: fit-content;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const HideMedium = styled.span`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const ShowMedium = styled.span`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: contents;
  `};
`
const NetworkCard = styled(RedCard)`
  background-color: ${(props) => (props.className === 'lending' ? '#ffc7c4' : 'rgba(243,132,30,0.05)')}
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const ProtocolIcon = styled.div`
  display: inline-block;
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName,
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  width: fit-content;
  margin: 0 auto;
  font-weight: 600;
  opacity: 0.5;

  &.${activeClassName} {
    opacity: 1;
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
    opacity: 0.7;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  :focus {
    opacity: 1;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.75rem;
  `}
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName,
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 auto;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.FUJI]: 'Fuji',
  [ChainId.AVALANCHE]: 'Avalanche',
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const [darkMode, toggleDarkMode] = useDarkModeManager()
  const history = useHistory()
  const currentLocation = useLocation().pathname
  const [isLendingActive, setLendingActive] = useState(currentLocation.includes('lending'))
  const isSearchActive = currentLocation.endsWith('search')
  const className = isLendingActive ? 'lending' : isSearchActive ? 'search' : undefined

  const onKeyDown = (event: KeyboardEvent) => {
    // CMD/CTRL + K pressed
    if ((event.metaKey || (event.ctrlKey && !isMacOs)) && event.key === 'k') {
      if (!isSearchActive) {
        event.preventDefault()
        history.push('/search')
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  })

  useEffect(() => {
    if (currentLocation.endsWith('lending') || currentLocation.endsWith('lending/portfolio')) {
      setLendingActive(true)
    } else {
      setLendingActive(false)
    }
  }, [isLendingActive, currentLocation])

  return (
    <HeaderFrame className={className}>
      <HideMedium>
        <ProtocolIcon>
          <img width={'40px'} src={darkMode ? LogoDark : Logo} alt="logo" />
        </ProtocolIcon>
      </HideMedium>
      <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
        <Web3Status />
      </AccountElement>
    </HeaderFrame>
  )
}
