import styled from 'styled-components'

const RoundContainer = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 0.5rem;
`

export default RoundContainer
