import Numeral from 'numeral'

import { ethers } from 'ethers'

// @ethersproject
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'

// @traderjoe-xyz sdk
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, CAVAX, ROUTER_ADDRESS } from '@traderjoe-xyz/sdk'
import { abi as IJoeRouterABI } from '@traderjoe-xyz/core/artifacts/contracts/traderjoe/interfaces/IJoeRouter02.sol/IJoeRouter02.json'

// state
import { TokenAddressMap } from 'state/lists/hooks'

// entities
import { Fraction } from 'entities/index'

export const BN_0 = BigNumber.from(0)
export const BN_8 = BigNumber.from(8)
export const BN_18 = BigNumber.from(10).pow(18)
export const BN_36 = BigNumber.from(10).pow(36)
export const BN_54 = BigNumber.from(10).pow(54)

const toFixed = (value: string, decimals: number): string => {
  const flt = parseFloat(value).toFixed(decimals)
  return flt
}

export const formatFromBalance = (value: BigNumber | undefined, decimals = 18): string => {
  if (value) {
    return Fraction.from(BigNumber.from(value), BigNumber.from(10).pow(decimals)).toString()
  } else {
    return ''
  }
}
export const formatToBalance = (value: string | undefined, decimals = 18) => {
  if (value) {
    return {
      value: ethers.utils.parseUnits(toFixed(value, decimals), decimals),
      decimals: decimals,
    }
  } else {
    return { value: BigNumber.from(0), decimals: decimals }
  }
}

export const isEmptyValue = (text: string) =>
  ethers.BigNumber.isBigNumber(text)
    ? ethers.BigNumber.from(text).isZero()
    : text === '' || text.replace(/0/g, '').replace(/\./, '') === ''

export const parseBalance = (value: string, decimals = 18) => {
  return ethers.utils.parseUnits(value || '0', decimals)
}

export function formattedPercent(percentString: any, places = 5) {
  const percent = parseFloat(percentString)
  if (!percent || percent === 0) {
    return '0%'
  }
  if (percent < 0.0001 && percent > 0) {
    return '< 0.01%'
  }
  if (percent < 0 && percent > -0.0001) {
    return '< 0.01%'
  }
  const fixedPercent = percent.toFixed(places)
  if (fixedPercent === '0.00') {
    return '0%'
  }
  if (Number(fixedPercent) > 0) {
    if (Number(fixedPercent) > 100) {
      return `${percent?.toFixed(0).toLocaleString()}%`
    } else {
      return `${fixedPercent}%`
    }
  } else {
    return `${fixedPercent}%`
  }
}

// Vision Formatting
const toK = (num: string) => {
  return Numeral(num).format('0.[00]a')
}

// using a currency library here in case we want to add more in future
const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const formattedNum = (number: any, usd = false, places = 5) => {
  if (isNaN(number) || number === '' || number === undefined || number === null) {
    return usd ? '$0.00' : '0'
  }
  const num = parseFloat(number)

  if (num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0))
  }

  if (num === 0) {
    return usd ? '$0' : '0'
  }

  if (num < 0.0001 && num > 0) {
    return usd ? '< $0.01' : places === 0 ? '< 0.01' : '< 0.0001'
  }

  if (num > 1000) {
    return usd
      ? '$' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
      : '' + Number(parseFloat(String(num)).toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return '$' + Number(parseFloat(String(num)).toFixed(4))
    } else {
      const usdString = priceFormatter.format(num)
      return '$' + usdString.slice(1, usdString.length)
    }
  }

  return parseFloat(String(num)).toFixed(places)
}

export const formatBalance = (value: ethers.BigNumberish, decimals = 18, maxFraction = 0) => {
  const formatted = ethers.utils.formatUnits(value, decimals)
  if (maxFraction > 0) {
    const split = formatted.split('.')
    if (split.length > 1) {
      return split[0] + '.' + split[1].substr(0, maxFraction)
    }
  }
  return formatted
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
export function isAddressString(value: any): string {
  try {
    return getAddress(value)
  } catch {
    return ''
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  4: 'rinkeby',
  43113: 'avax-test',
  43114: 'avax',
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix =
    chainId === ChainId.RINKEBY ? `https://${ETHERSCAN_PREFIXES[chainId]}.etherscan.io` : `https://snowtrace.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/blocks/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string | undefined, chars = 4): string {
  if (address) {
    const parsed = isAddress(address)
    if (!parsed) {
      throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
  }
  return ''
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(2000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(chainId: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(
    chainId ? ROUTER_ADDRESS[chainId] : ROUTER_ADDRESS[ChainId.AVALANCHE],
    IJoeRouterABI,
    library,
    account
  )
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === CAVAX) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export const getViewportWidth = () => {
  return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}

// string type guard
export const isString = (
  value: null | string | boolean | number | (string | boolean | number)[] | undefined
): value is string => {
  return typeof value === 'string'
}

// number type guard
export const isNumber = (
  value: null | string | boolean | number | (string | boolean | number)[] | undefined
): value is number => {
  return typeof value === 'number'
}

export const formatChartPrice = (price: number) => {
  return price < 0
    ? 0
    : price < 0.001
    ? price.toFixed(10)
    : price >= 0.001 && price < 0.01
    ? price.toFixed(8)
    : price >= 0.01 && price < 1
    ? price.toFixed(6)
    : price.toFixed(3)
}

export const capitalize = (word: string | undefined): string => {
  if (word?.length === 0 || word === undefined) return ''
  const separateWord = word.toLowerCase().split(' ')
  for (let i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1)
  }
  return separateWord.join(' ')
}
