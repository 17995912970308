import { useMemo } from 'react'

import { abi as PANGOLIN_FACTORY_ABI } from '@pangolindex/exchange-contracts/artifacts/contracts/pangolin-core/PangolinFactory.sol/PangolinFactory.json'
import EIP_2612 from 'constants/abis/eip_2612.json'

// @ethersproject
import { Contract } from '@ethersproject/contracts'

// @traderjoe-xyz sdk
import {
  WAVAX,
  BAR_ADDRESS,
  BORINGHELPER_ADDRESS,
  BORINGHELPER_MCV3_ADDRESS,
  JOE_ADDRESS,
  MASTERCHEF_ADDRESS,
  MASTERCHEF_V3_ADDRESS,
  ROLL_ADDRESS,
  BORINGTOKENSCANNER_ADDRESS,
  BORINGDASHBOARD_ADDRESS,
  ZAP_ADDRESS,
  UNITROLLER_ADDRESS,
  JAVAX_ADDRESS,
  JOELENS_ADDRESS,
  FARMLENS_ADDRESS,
  MAXIMILLION_ADDRESS,
  LAUNCH_EVENT_LENS_ADDRESS,
  ROCKET_JOE_STAKING_ADDRESS,
  ROCKET_JOE_TOKEN_ADDRESS,
  STABLE_JOE_STAKING_ADDRESS,
  ChainId,
} from '@traderjoe-xyz/sdk'
import ZAP_ABI from '@traderjoe-xyz/core/abi/Zap.json'
import BAR_ABI from '@traderjoe-xyz/core/abi/JoeBar.json'
import JOE_ABI from '@traderjoe-xyz/core/abi/JoeToken.json'
import ROLL_ABI from '@traderjoe-xyz/core/abi/JoeRoll.json'
import JOELENS_ABI from '@traderjoe-xyz/lending/abi/JoeLens.json'
import FARM_LENS_ABI from '@traderjoe-xyz/core/abi/FarmLens.json'
import JAVAX_ABI from '@traderjoe-xyz/lending/abi/JWrappedNative.json'
import JOETROLLER_ABI from '@traderjoe-xyz/lending/abi/Joetroller.json'
import MAXIMILLION_ABI from '@traderjoe-xyz/lending/abi/Maximillion.json'
import MASTERCHEF_ABI from '@traderjoe-xyz/core/abi/MasterChefJoeV2.json'
import BORINGHELPER_ABI from '@traderjoe-xyz/core/abi/BoringHelperV1.json'
import MASTERCHEF_V3_ABI from '@traderjoe-xyz/core/abi/MasterChefJoeV3.json'
import SIMPLEREWARDER_ABI from '@traderjoe-xyz/core/abi/SimpleRewarderPerSec.json'
import BORINGDASHBOARD_ABI from '@traderjoe-xyz/core/abi/BoringCryptoDashboardV2.json'
import JCOLLATERALCAPERC20_ABI from '@traderjoe-xyz/lending/abi/JCollateralCapErc20.json'
import BORINGTOKENSCANNER_ABI from '@traderjoe-xyz/core/abi/BoringCryptoTokenScanner.json'
import LAUNCH_EVENT_ABI from '@traderjoe-xyz/rocket-joe/abi/contracts/LaunchEvent.sol/LaunchEvent.json'
import LAUNCH_EVENT_LENS_ABI from '@traderjoe-xyz/rocket-joe/abi/contracts/LaunchEventLens.sol/LaunchEventLens.json'
import ROCKET_JOE_STAKING_ABI from '@traderjoe-xyz/rocket-joe/abi/contracts/RocketJoeStaking.sol/RocketJoeStaking.json'
import ROCKET_JOE_TOKEN_ABI from '@traderjoe-xyz/rocket-joe/abi/contracts/RocketJoeToken.sol/RocketJoeToken.json'
// import STABLE_JOE_STAKING_ABI from '@traderjoe-xyz/core/deployments/rinkeby/StableJoeStaking.json'
import STABLE_JOE_STAKING_ABI from 'constants/abis/stableJoeStaking.json'
import { abi as IJoePairABI } from '@traderjoe-xyz/core/artifacts/contracts/traderjoe/interfaces/IJoePair.sol/IJoePair.json'

// constants
import WETH_ABI from 'constants/abis/weth.json'
import ERC20_ABI from 'constants/abis/erc20.json'
import { PANGOLIN_FACTORY_ADDRESS } from 'constants/swap'
import { ERC20_BYTES32_ABI } from 'constants/abis/erc20'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from 'constants/multicall'
import { HAT_ADDRESS } from 'constants/index'
import HAT_ABI from 'constants/abis/hat.json'
import HAT_BOUNDING_ABI from 'constants/abis/hatBoundingCurve.json'

// utils
import { getContract } from 'utils'

// hooks
import { useActiveWeb3React } from './index'

// returns null on errors
export function useContract(
  address: string | undefined | false,
  ABI: any,
  withSignerIfPossible = true
): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WAVAX[chainId]?.address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IJoePairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useJoeContract(withSignerIfPossible = true): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && JOE_ADDRESS[chainId], JOE_ABI, withSignerIfPossible)
}

export function useJoeBarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && BAR_ADDRESS[chainId], BAR_ABI, withSignerIfPossible)
}

export function useMasterJoeContract(v3?: boolean, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const masterchefAddress = v3 ? chainId && MASTERCHEF_V3_ADDRESS[chainId] : chainId && MASTERCHEF_ADDRESS[chainId]
  const abi = v3 ? MASTERCHEF_V3_ABI : MASTERCHEF_ABI
  return useContract(masterchefAddress, abi, withSignerIfPossible)
}

export function useJoeRollContract(withSignerIfPossible = true): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && ROLL_ADDRESS[chainId], ROLL_ABI, withSignerIfPossible)
}

export function useFarmLensContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? FARMLENS_ADDRESS[chainId] : undefined, FARM_LENS_ABI, false)
}

export function useBoringHelperContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BORINGHELPER_ADDRESS[chainId] : undefined, BORINGHELPER_ABI, false)
}

export function useBoringHelperMCV3Contract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BORINGHELPER_MCV3_ADDRESS[chainId] : undefined, BORINGHELPER_ABI, false)
}

export function useBoringTokenScannerContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BORINGTOKENSCANNER_ADDRESS[chainId] : undefined, BORINGTOKENSCANNER_ABI, false)
}

export function useBoringDashboardContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? BORINGDASHBOARD_ADDRESS[chainId] : undefined, BORINGDASHBOARD_ABI, false)
}

export function usePangolinFactoryContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? PANGOLIN_FACTORY_ADDRESS[chainId] : undefined, PANGOLIN_FACTORY_ABI, false)
}

export function useZapContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? ZAP_ADDRESS[chainId] : undefined, ZAP_ABI, withSignerIfPossible)
}

export function useJoetrollerContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && UNITROLLER_ADDRESS[chainId], JOETROLLER_ABI, withSignerIfPossible)
}

export function useJTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  const isJAVAX = tokenAddress === JAVAX_ADDRESS[chainId ?? ChainId.AVALANCHE]
  const abi = isJAVAX ? JAVAX_ABI : JCOLLATERALCAPERC20_ABI
  return useContract(tokenAddress, abi, withSignerIfPossible)
}

export function useJoeLensContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && JOELENS_ADDRESS[chainId], JOELENS_ABI, withSignerIfPossible)
}

export function useMaximillionContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MAXIMILLION_ADDRESS[chainId], MAXIMILLION_ABI, withSignerIfPossible)
}

export function useSimpleRewarderContract(address?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, SIMPLEREWARDER_ABI, withSignerIfPossible)
}

export const useLaunchEventContract = (
  address: string | undefined,
  withSignerIfPossible?: boolean
): Contract | null => {
  return useContract(address, LAUNCH_EVENT_ABI, withSignerIfPossible)
}

export const useLaunchEventLensContract = (): Contract | null => {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && LAUNCH_EVENT_LENS_ADDRESS[chainId], LAUNCH_EVENT_LENS_ABI, false)
}

export const useRocketJoeStakingContract = (withSignerIfPossible?: boolean): Contract | null => {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && ROCKET_JOE_STAKING_ADDRESS[chainId], ROCKET_JOE_STAKING_ABI, withSignerIfPossible)
}

export const useRocketJoeTokenContract = (withSignerIfPossible?: boolean): Contract | null => {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && ROCKET_JOE_TOKEN_ADDRESS[chainId], ROCKET_JOE_TOKEN_ABI, withSignerIfPossible)
}

export const useStableJoeStakingContract = (withSignerIfPossible?: boolean): Contract | null => {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && STABLE_JOE_STAKING_ADDRESS[chainId], STABLE_JOE_STAKING_ABI, withSignerIfPossible)
}

export const useHatContract = (withSignerIfPossible?: boolean): Contract | null => {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && HAT_ADDRESS[chainId], HAT_ABI, withSignerIfPossible)
}
