import { useCallback } from 'react'

import { nanoid } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { TokenList } from '@pangolindex/token-lists'

// state
import { AppDispatch } from 'state'
import { fetchTokenList } from 'state/lists/actions'

// utils
import getTokenList from 'utils/getTokenList'

export function useFetchListCallback(): (listUrl: string) => Promise<TokenList> {
  const dispatch = useDispatch<AppDispatch>()

  const ensResolver = useCallback(() => {
    throw new Error('Could not construct mainnet ENS resolver')
  }, [])

  return useCallback(
    async (listUrl: string) => {
      const requestId = nanoid()
      dispatch(fetchTokenList.pending({ requestId, url: listUrl }))
      return getTokenList(listUrl, ensResolver)
        .then((tokenList) => {
          dispatch(fetchTokenList.fulfilled({ url: listUrl, tokenList, requestId }))
          return tokenList
        })
        .catch((error) => {
          console.debug(`Failed to get list at url ${listUrl}`, error)
          dispatch(
            fetchTokenList.rejected({
              url: listUrl,
              requestId,
              errorMessage: error.message,
            })
          )
          throw error
        })
    },
    [dispatch, ensResolver]
  )
}
