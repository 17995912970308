import { ChainId, Token, JOE_ADDRESS } from '@traderjoe-xyz/sdk'

export const HOSTNAME = 'www.traderjoexyz.com'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const SNAPSHOT_PAGE = 'https://snapshot.org/#/joe-defi.eth'
export const SMALL_NUMBER = 0
export const FEE_RATE = 0.0025 // 0.25%
export const POOL_SHARE_PRECISION = 10000000
export const MAX_UINT_256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export const JOE: { [chainId in ChainId]: Token } = {
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, JOE_ADDRESS[ChainId.RINKEBY], 18, 'JOE', 'Joe'),
  [ChainId.FUJI]: new Token(ChainId.FUJI, JOE_ADDRESS[ChainId.FUJI], 18, 'JOE', 'Joe'),
  [ChainId.AVALANCHE]: new Token(ChainId.AVALANCHE, JOE_ADDRESS[ChainId.AVALANCHE], 18, 'JOE', 'Joe'),
}

export const NetworkContextName = 'NETWORK'

export const AVALANCHE_CHAIN_PARAMS = {
  chainId: '0xa86a', // A 0x-prefixed hexadecimal chainId
  chainName: 'Avalanche Mainnet C-Chain',
  nativeCurrency: {
    name: 'Avalanche',
    symbol: 'AVAX',
    decimals: 18,
  },
  rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
  blockExplorerUrls: ['https://snowtrace.io/'],
}

export const POOL_PAGINATION_SIZE = 500

export const IS_IN_IFRAME = window.parent !== window

export const HAT_ADDRESS = {
  [ChainId.FUJI]: '0xD436B2cfe62b726EbBbe12a91D1F8Ef3da7fC4Ea',
  [ChainId.AVALANCHE]: '0x82FE038Ea4b50f9C957da326C412ebd73462077C',
  [ChainId.RINKEBY]: '',
}
