import React from 'react'
import { ImageProps } from 'rebass'

interface SwitchTokenButtonProps extends Pick<ImageProps, 'onClick'> {
  borderColor: string
  size?: string
}

export default function SwitchTokenButton({ size = '42', borderColor, ...rest }: SwitchTokenButtonProps) {
  return (
    <div {...rest}>
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21" cy="21" r="20" stroke={borderColor} />
        <path
          d="M12 17L15 14L18 17"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 25L27 28L30 25"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
