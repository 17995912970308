import { useCallback, useState } from 'react'
import { useActiveWeb3React } from './index'

// hooks
import { useTransactionAdder } from 'state/transactions/hooks'
import { useHatContract } from 'hooks/useContract'

// utils
import { BN_18 } from 'utils'

const useHat = () => {
  const addTransaction = useTransactionAdder()
  const hatContract = useHatContract()
  const [txHash, setTxHash] = useState('')

  const redeem = useCallback(async () => {
    try {
      const tx = await hatContract?.burn(BN_18)
      setTxHash(tx.hash)
      return addTransaction(tx, { summary: 'Redeem 1 HAT' })
    } catch (e) {
      return e
    }
  }, [addTransaction, hatContract])

  return { txHash, redeem }
}

export default useHat
