import React, { useContext } from 'react'

import styled, { ThemeContext } from 'styled-components'
import { AlertCircle, CheckCircle } from 'react-feather'

// hooks
import { useActiveWeb3React } from 'hooks'

// styling
import { TYPE } from 'theme'
import { ExternalLink } from 'theme/components'

// utils
import { getEtherscanLink } from 'utils'

// components
import { AutoRow } from 'components/Row'
import { AutoColumn } from 'components/Column'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

interface TransactionPopupProps {
  hash: string
  success?: boolean
  summary?: string
}

export default function TransactionPopup({ hash, success, summary }: TransactionPopupProps) {
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? <CheckCircle color={theme.green1} size={24} /> : <AlertCircle color={theme.red1} size={24} />}
      </div>
      <AutoColumn gap="8px">
        <TYPE.body fontWeight={500}>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</TYPE.body>
        {chainId && (
          <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
            <TYPE.body style={{ color: theme.primary2 }}>View on Snowtrace</TYPE.body>
          </ExternalLink>
        )}
      </AutoColumn>
    </RowNoFlex>
  )
}
