import React from 'react'

// @traderjoe-xyz sdk
import { Percent } from '@traderjoe-xyz/sdk'

// constants
import { ONE_BIPS } from 'constants/swap'

// utils
import { warningSeverity } from 'utils/prices'

// components
import { ErrorText } from './styleds'

interface FormattedPriceImpactProps {
  priceImpact?: Percent
}

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact }: FormattedPriceImpactProps) {
  return (
    <ErrorText fontWeight={500} fontSize={14} severity={warningSeverity(priceImpact)}>
      {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
    </ErrorText>
  )
}
