import React, { Suspense } from 'react'

import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

// components
import Header from 'components/Header'
import Popups from 'components/Popups'
import URLWarning from 'components/Header/URLWarning'
import Web3ReactManager from 'components/Web3ReactManager'

// theme
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'

// utils
import ScrollToTop from 'utils/ScrollToTop'

// pages
import Home from './Home'
import { RedirectPathToHome } from './Home/redirects'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.bg8};
  padding-top: '4.75em';
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 0;
  `}
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  z-index: 2;
  transition: opacity 250ms ease-out;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
`

const App = () => {
  return (
    <Suspense fallback={null}>
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <URLWarning />
        <Helmet>
          <title>Joe Hat</title>
          <meta name="description" content="Joe Hat" />
        </Helmet>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <ScrollToTop />
          <Popups />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/" component={Home} />
              <Route component={RedirectPathToHome} />
            </Switch>
          </Web3ReactManager>
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}

const AppRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

export default AppRouter
