export enum SupportedChainId {
  RINKEBY = 4,
  FUJI = 43113,
  AVAX = 43114,
}

export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.RINKEBY,
  SupportedChainId.FUJI,
  SupportedChainId.AVAX,
]
