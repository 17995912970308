import React, { useMemo } from 'react'

import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components'
import { Colors } from './styled'
import { Text, TextProps } from 'rebass'

// state
import { useIsDarkMode } from 'state/user/hooks'

import './index.css'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

const white = '#FFFFFF'
const black = '#000000'

function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#242424', // primary dark text
    text2: darkMode ? '#E1E4F5' : '#232324', // secondary dark text
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#AEAFC2', // light label text, e.g. balance
    text5: darkMode ? '#4F5180' : '#BDBDBD', // gray subheader

    // backgrounds / greys
    bg1: darkMode ? '#24264C' : white, // input panel bg
    bg2: darkMode ? '#14163b' : '#FAFAFF', // main background,
    bg3: darkMode ? '#40444F' : '#EDEEF2', // disabled button
    bg4: darkMode ? '#565A69' : '#EBEBF5', // input border
    bg5: darkMode ? '#6C7284' : '#F9FBFD', // light gray
    bg6: darkMode ? '#f5ab41' : '#f5ab41', // farms color
    bg7: darkMode ? '#2c2e55' : '#f5f5ff', // portfolio header color
    bg8: darkMode ? '#14163b' : '#f2f6fa', // new bg color
    bg9: darkMode ? '#24264c' : '#edf1f7', // footer bg color
    bg10: darkMode ? '#14163b' : white, // lending bg color
    bg11: darkMode ? '#888def' : white, // launch bg color
    bg12: darkMode ? '#24264c' : '#e9f0f7', // launch progress bar color
    bg13: darkMode ? '#1d1f45' : '#f9fbfd', // launch card bg color
    bg14: darkMode ? '#1d1f45' : '#f6f6ff', // launch detail bg color

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',
    userBG: darkMode ? '#1d1f45' : '#f9fbfd', // user pool panel, launch summary
    cardBG: darkMode ? '#A1A5FC' : '#A1A5FC', // purple card bg
    portfolioButtonBG: darkMode ? '#393b6e' : white,
    launchIndicatorActive: darkMode ? '#7e84ff' : '#7e84ff',
    launchIndicatorInactive: darkMode ? '#393b6e' : '#cccefe',
    launchCardBorder: darkMode ? 'transparent' : '#ebebf5',
    borderColor: darkMode ? '#393b6e' : '#ebebf5', // search bar, launch details

    // search
    searchBG: darkMode ? '#14163B' : '#A1A5FC',
    searchClearButton: darkMode ? '#2C2E55' : '#AAAEFC',
    searchMobileHeaderBottomBorder: darkMode ? '#2C2E55' : '#B4B7FD',
    searchInputPlaceholder: darkMode ? '#2C2E55' : '#D0D2FE',
    homeSearchText: darkMode ? '#4F5180' : '#AEAFC2',
    searchResultHover: darkMode ? '#2C2E55' : '#AAAEFC',

    // nav bar
    headerButtonIcon: darkMode ? white : black,
    headerButtonHover: darkMode ? '#1A1E4B' : white,
    headerButtonBorderHover: darkMode ? '#5B8FE3' : '#EBEBF5',

    // tile, banner, hero colors
    farmBG: darkMode ? '#ebc66c' : '#ffe7aC',
    poolBG: darkMode ? '#6fd7e3' : '#90e6f0',
    earnBG: darkMode ? '#f8928c' : '#fadbda',
    tradeBG: darkMode ? '#71b176' : '#c7efca',
    stakeBG: darkMode ? '#ffe9ef' : '#fadadd',
    farmMarketBG: darkMode ? '#f5ab41' : '#f5ab41',
    lendBG: darkMode ? '#8886ff' : '#cecdff',
    avaxCardBG: darkMode ? '#f7746d' : '#ffc7c4',
    rJoeIconBG: '#a1fcd6',
    xJoeIconBG: '#2e2e2e',
    sJoeIconBG: '#d5d7ff',
    veJoeIconBG: '#fcf3a1',

    //primary colors
    primary1: darkMode ? '#3B3D65' : '#EDEEFF', // Button fill, lavender bg
    primary2: darkMode ? '#7E84FF' : '#7E84FF', // Button border, purple
    primary3: darkMode ? '#4D8FEA' : '#EFEFEF', // gray light
    primary4: darkMode ? '#376bad70' : '#7408F8',
    primary5: darkMode ? '#7408F8' : '#7408F8', // max button
    primary6: darkMode ? '#2172E5' : '#FFFFFF',

    // color text
    primaryText1: darkMode ? '#6da8ff' : '#ffffff',
    launchSubheaderText: darkMode ? '#e1e4f5' : '#888d9b',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#F29034', // blue/orange; used by wallet messages
    secondary2: darkMode ? '#2172E5' : '#fadbda', // blue/pink; used by staking highlight panel
    secondary3: darkMode ? '#2172E5' : '#f8928c', // blue/dark-pink; used by staking ratio badge

    // other
    red1: '#FAEDF0',
    red2: '#F7746D',
    red3: '#f78b86',
    green1: '#27AE60',
    green2: '#54C273',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: '#2172E5',
    orange: '#F29034',
    avaxRed: '#E84142',
    portfolioButtonIconColor: darkMode ? white : black,
    boxShadow: darkMode ? 'rgba(61, 64, 128, 0.1)' : '#ececf6',
    borderRadius: '10px',
  }
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary2'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={400} color={'white'} fontSize={14} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'text1'} {...props} />
  },
  panelHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={16} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'text5'} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  mediumGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text4'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  },
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Averta CY Regular', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Averta CY Regular', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;

}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1};
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.bg2};
}

a {
  color: ${({ theme }) => theme.primary5};
}
`
